<template>
  <!-- start mobile view -->
  <div v-if="this.$root.mobile">
    <!-- start toolbar -->

    <!-- info tap -->

    <div class="mb-4">
      <MedicalFileInfo
        v-if="showMfInfo"
        :mfid="$file.getCurrentFile() ? $file.getCurrentFile().id : mfid"
      />
    </div>
    <MedicalFileEntries :mfid="this.mfid" />

    <!-- add md file -->
    <v-fab-transition v-if="$root.viaid != 0 || $root.mf != 0">
      <v-btn
        color="primary"
        fab
        dark
        small
        fixed
        bottom
        right
        large
        :class="$root.tabspadding > 0 ? 'mb-25' : 'mb-12'"
        @click="sheet = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- <MobileAdd
      :dialog="showAdd"
      :formType="formType"
      @closeClicked="showAdd = false"
    /> -->

    <HistoryForm
      v-if="formType == 'historyForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />
    <VitalsForm
      v-if="formType == 'vitalsForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />
    <DiagnosesForm
      v-if="formType == 'diagnosesForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />
    <PrescriptionForm
      v-if="formType == 'prescForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />
    <FilessForm
      v-if="formType == 'filesForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />
    <NotesForm
      v-if="formType == 'notesForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />

    <ConsultationForm
      v-if="formType == 'consultationForm'"
      :dialog="showAdd"
      :mfid="this.mfid"
      @backToStepOneAndToast="backAndToast($event)"
      @closeClicked="showAdd = false"
    />

    <div style="width: 100%">
      <v-bottom-sheet v-model="sheet" persistent>
        <v-card
          class="rounded-lg"
          style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
          :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
        >
          <v-toolbar flat dark color="white" class="text-primary pt-3">
            <!-- <v-toolbar-title>{{ $t('MF.entries') }}</v-toolbar-title> -->
            <v-toolbar-title>{{ $t("MF.add") }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark color="primary" @click="sheet = !sheet">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- display entries -->
          <div class="p-3">
            <v-list>
              <v-list-item-group v-model="selectedEntry" color="primary">
                <v-list-item
                  v-for="(entry, i) in entries"
                  :key="i"
                  @click="showAddForm(entry.label)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ entry.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
      </v-bottom-sheet>
    </div>
  </div>
  <!-- end mobile view -->

  <!-- start web view -->
  <div v-else>
    <div>
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 pt-0">
          <MedicalFileInfo
            v-if="showMfInfo"
            :mfid="$root.mf == 0 ? this.mfid : $root.mf"
          />
        </div>
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 pt-0 pb-0 mb-0">
          <!--begin::Card-->
          <v-card elevation="0" color="transparent">
            <div
              style="display:flex;    height: 60px; align-items: center;"
              class="py-2 px-1 "
            >
              <h4 class="text-primary font-weight-bolder m-0">
                {{ $t("MF.entries") }}
              </h4>
              <v-spacer />
              <template v-if="$root.viaid != 0 || $root.mf != 0">
                <v-btn
                  color="primary"
                  elevation="0"
                  rounded
                  @click="dialog = true"
                  :disabled="loadingStatus"
                >
                  <v-icon left dark class="mr-3"> mdi-plus </v-icon
                  >{{ $t("MF.add") }}
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  rounded
                  @click="exportPDF()"
                  :disabled="loadingStatus"
                  class="ml-3"
                >
                  <unicon
                    style=""
                    name="cloud-download"
                    fill="#FFFFFF"
                    height="20"
                    class="mr-2"
                  ></unicon>
                  {{ "Export" }}
                </v-btn>
              </template>
            </div>
            <v-card-text class="m-0 p-0">
              <div class="listitem mb-5" style="display:flex; padding:16px">
                <div
                  v-for="(entry, i) in mftabs"
                  :key="i"
                  @click="tab = entry.tab"
                >
                  <div
                    class="pushx"
                    v-if="entry.tab == tab"
                    :style="'background-color:#f2f7fd'"
                    style="transition: width 0.1s linear;height:50px; min-width:50px;border-radius:7px; display:flex; align-items: center;justify-content: center;"
                  >
                    <img
                      class="mx-3"
                      height="25px"
                      width="25px"
                      :src="'/media/svg/icons/Doctory/' + entry.icon + '.svg'"
                    />

                    <span
                      v-show="entry.tab == tab"
                      :class="$root.lang == 'ar' ? 'pl-3' : 'pr-3'"
                      >{{ entry.text }}</span
                    >
                  </div>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="pushx"
                        :class="'bg-hover-gray-200'"
                        style="transition: width 0.1s linear;height:50px; min-width:50px;border-radius:7px; display:flex; align-items: center;justify-content: center;"
                      >
                        <img
                          class="mx-3"
                          height="25px"
                          width="25px"
                          :src="
                            '/media/svg/icons/Doctory/' + entry.icon + '.svg'
                          "
                        />
                      </div>
                    </template>
                    <span>{{ entry.text }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-tabs hide-slider height="0" v-model="tab">
                <v-tabs-items v-model="tab" background-color="transparent">
                  <v-tab-item style="background-color:transparent !important;">
                    <History :mfid="this.mfid" />
                  </v-tab-item>
                  <v-tab-item>
                    <Vital :mfid="this.mfid" />
                  </v-tab-item>
                  <v-tab-item>
                    <Diagnoses :mfid="this.mfid" />
                  </v-tab-item>
                  <v-tab-item>
                    <Prescription :mfid="this.mfid" />
                  </v-tab-item>
                  <v-tab-item>
                    <Files :mfid="this.mfid" />
                  </v-tab-item>
                  <v-tab-item>
                    <Notes :mfid="this.mfid" />
                  </v-tab-item>
                  <v-tab-item>
                    <v-card elevation="0" style="border-radius: 15px;">
                      <v-toolbar
                        dark
                        color="blue darken-3"
                        class="mb-1"
                        elevation="0"
                        style="
                          border-top-left-radius: 16 !important;
                          border-top-right-radius: 16 !important;
                        "
                      >
                        <span
                          class="font-weight-bold font-size-h4"
                          style="width:15%"
                          >{{ $t("MF.changes.summery") }}</span
                        >
                      </v-toolbar>
                      <v-card-text>
                        <Changes :mfid="this.mfid"></Changes>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <Add
      :mfid="this.mfid"
      :dialog="dialog"
      @closeClicked="closeDialog($event)"
    />

    <v-dialog v-model="loadingStatus" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <!-- end web view -->
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import ApiService from "@/core/services/api.service";

export default {
  name: "file",
  props: ["id", "isdialog"],
  components: {
    // AvatarLarge,
    Changes: () => import("./comp/changes.vue"),
    History: () => import("./comp/history.vue"),
    Vital: () => import("./comp/vital.vue"),
    Diagnoses: () => import("./comp/diagnoses.vue"),
    Notes: () => import("./comp/note.vue"),
    Files: () => import("./comp/files.vue"),
    Prescription: () => import("./comp/prescription.vue"),
    Add: () => import("./comp/add.vue"),
    MedicalFileInfo: () => import("./comp/MedicalFileInfo.vue"),
    MedicalFileEntries: () => import("./comp/MedicalFileEntries"),
    // MobileAdd,
    HistoryForm: () => import("./comp/addcomp/historyForm.vue"),
    VitalsForm: () => import("./comp/addcomp/vitalsForm.vue"),
    NotesForm: () => import("./comp/addcomp/notesForm.vue"),
    DiagnosesForm: () => import("./comp/addcomp/diagnosesForm.vue"),
    FilessForm: () => import("./comp/addcomp/filesForm.vue"),
    PrescriptionForm: () => import("./comp/addcomp/presForm.vue"),
    ConsultationForm: () => import("./comp/addcomp/ConsultationForm")
  },
  data() {
    return {
      mf: null,
      mfid: 0,
      dialog: false,
      error: "",
      showMfInfo: true,
      showMfEntries: false,
      reloadupcoming: false,
      showAdd: false,
      formType: "",
      sheet: false,
      selectedEntry: false,
      loadingStatus: false,
      entries: [],
      history: [],
      vitals: [],
      notes: [],
      diagnoses: [],
      tab: 0,
      drentries: [
        {
          label: "historyForm",
          text: this.$t("MF.medicalHistoryLabel"),
          icon: ""
        },
        { label: "vitalsForm", text: this.$t("MF.vitalslabel"), icon: "" },
        {
          label: "diagnosesForm",
          text: this.$t("MF.diagnosesLabel"),
          icon: ""
        },
        { label: "notesForm", text: this.$t("MF.notesLabel"), icon: "" },
        { label: "prescForm", text: this.$t("MF.prescriptionLabel"), icon: "" },
        { label: "filesForm", text: this.$t("MF.filesLabel"), icon: "" },
        {
          label: "consultationForm",
          text: this.$t("MF.consultationLabel"),
          icon: ""
        }
      ],
      ptentries: [
        { label: "historyForm", text: this.$t("MF.medicalHistoryLabel") },
        { label: "vitalsForm", text: this.$t("MF.vitalslabel") },
        { label: "filesForm", text: this.$t("MF.filesLabel") }
      ],
      mftabs: [
        {
          label: "md_history",
          text: this.$t("MF.medicalHistoryLabel"),
          icon: "icons-pc",
          tab: 0
        },
        {
          label: "md_vitals",
          text: this.$t("MF.vitalslabel"),
          icon: "icons-monitoring",
          tab: 1
        },
        {
          label: "md_diagnoses",
          text: this.$t("MF.diagnosesLabel"),
          icon: "icons-doctor",
          tab: 2
        },
        {
          label: "md_presc",
          text: this.$t("MF.prescriptionLabel"),
          icon: "icons-pills",
          tab: 3
        },
        {
          label: "md_files",
          text: this.$t("MF.filesLabel"),
          icon: "icons-file-records",
          tab: 4
        },
        {
          label: "md_notes",
          text: this.$t("MF.notesLabel"),
          icon: "group-700",
          tab: 5
        },
        {
          label: "activities",
          text: this.$t("MF.changes.summery"),
          icon: "icons-nurse",
          tab: 6
        }
      ]
    };
  },
  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    }
  },
  beforeMount() {
    if (JSON.parse(localStorage.userInfo).role == 3) {
      this.entries = this.drentries;
    } else {
      this.entries = this.ptentries;
    }
  },
  mounted() {
    // console.log("mounted medical file");
    this.$root.title = this.$t("MF.mf");
    if (!this.isdialog) this.$root.currentpage = this.$route.name;
  },
  activated() {
    // console.log("activated medical file");
  },
  created() {
    this.getMF();
  },
  destroyed() {},

  methods: {
    getMF() {
      if (this.$root.doctormf != 0) {
        this.mfid = this.$root.doctormf;
      } else {
        this.mfid = this.$route.params.id;
      }
      /*/ var userInfo = JSON.parse(localStorage.userInfo).id;
      ApiService.get(
        "items",
        "medical_file/" +
          this.mfid +
          "?fields=owner.avatar.data,owner.first_name,owner.last_name,height,weight,blood_type,birthday,gender,full_name"
      )
        .then(({ data }) => {
          // console.log("mf data", data.data);
          // console.log(data.data);
          this.mf = data.data;
          this.$root.title = this.$t("MF.mf") + ": " + this.mf.full_name;
        })
        .catch(error => {
          this.error = error;
        });*/
    },

    closeDialog(d) {
      this.dialog = d;
    },
    showAddForm(formType) {
      this.sheet = !this.sheet;
      this.formType = formType;
      this.showAdd = true;
    },
    backAndToast(msg, append = false) {
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: "b-toaster-top-center",
        solid: true,
        variant: "success",
        appendToast: append
      });
    },

    async getMedicalFileItems() {
      try {
        if (this.$root.doctormf != 0) {
          this.mfid = this.$root.doctormf;
        } else {
          this.mfid = this.$route.params.id;
        }

        this.loadingStatus = true;
        const history = await ApiService.get(
          "items",
          "md_history?fields=history_type.id,history_type.type,id,date,incident_date,history_body&filter[medical_file]=" +
            this.mfid
        );

        const vitals = await ApiService.get(
          "items",
          "md_vitals?fields=vital_type.id,vital_type.name,vital_type.suffix,id,created_on,reading,method,health&filter[medical_file]=" +
            this.mfid
        );

        const notes = await ApiService.get(
          "items",
          "md_notes?fields=id,note_body,summary,owner.first_name,owner.last_name,owner.avatar.data,created_on&filter[medical_file]=" +
            this.mfid
        );

        const diagnoses = await ApiService.get(
          "items",
          "md_diagnoses?fields=id,examination,illness.id, illness.name,owner.first_name,owner.last_name,owner.avatar.data,created_on&filter[medical_file]=" +
            this.mfid
        );

        this.history = history.data.data;
        this.vitals = vitals.data.data;
        this.notes = notes.data.data;
        this.diagnoses = diagnoses.data.data;

        this.loadingStatus = false;
      } catch (error) {
        // console.log(error);
      }
    },

    async exportPDF() {
      await this.getMedicalFileItems();
      let i;
      const doc = new jsPDF();

      let userName = "Name: " + this.mf.full_name;

      doc
        .setFont("helvetica")
        .setFontSize(14)
        .text(userName, 14, 14);

      doc.setLineWidth(0.01).line(14, 16, doc.internal.pageSize.width - 14, 16);

      // medical history table
      i = 1;
      let historyBody = [];
      for (let item of this.history) {
        const historyItem = [];
        historyItem.push(i++);
        historyItem.push(item.incident_date);
        historyItem.push(item.history_type.type);
        historyItem.push(item.history_body);

        historyBody.push(historyItem);
      }

      doc.autoTable({
        head: [["#", "Incident Date", "Type", "History Details"]],
        body: historyBody,
        margin: { top: 33 }
      });

      // vitals table
      i = 1;
      let vitalsBody = [];
      for (let item of this.vitals) {
        const vitalItem = [];
        vitalItem.push(i++);
        vitalItem.push(item.reading);
        vitalItem.push(item.vital_type.name);
        vitalItem.push(item.vital_type.suffix);

        vitalsBody.push(vitalItem);
      }

      doc.autoTable({
        head: [["#", "Reading", "Type", "Suffix"]],
        body: vitalsBody,
        margin: { top: 25 }
      });

      // notes table
      i = 1;
      let notesBody = [];
      for (let item of this.notes) {
        const noteItem = [];
        noteItem.push(i++);
        noteItem.push(item.summary);
        noteItem.push(item.note_body);

        notesBody.push(noteItem);
      }

      doc.autoTable({
        head: [["#", "Summery", "Details"]],
        body: notesBody,
        margin: { top: 25 }
      });

      // diagnoses table
      i = 1;
      let diagnosesBody = [];
      for (let item of this.diagnoses) {
        const diagnosesItem = [];
        diagnosesItem.push(i++);
        diagnosesItem.push(item.icd.Description);
        diagnosesItem.push(item.examination);

        diagnosesBody.push(diagnosesItem);
      }

      doc.autoTable({
        head: [["#", "Illness", "Examination"]],
        body: diagnosesBody,
        margin: { top: 25 }
      });

      // doc
      //   .setFont('helvetica')
      //   .setFontSize(12)
      //   .text('Notes: ' + item.notes, 14, 20);

      doc
        .setFont("times", "italic")
        .setFontSize(11)
        // .setFontStyle('italic')
        .setTextColor(0, 0, 255)
        .text("@Doctory", 14, doc.internal.pageSize.height - 14);

      doc.save("medical file.pdf");
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}

.v-data-table tr td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1rem !important;
  text-transform: uppercase;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>

<style>
table tbody tr td {
  border: 0px !important;
  cursor: pointer;
}
</style>
